import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CollectionLink from "../../utils/generate-collection-link";
import Carousel from "../../atoms/carousel";
import CollectionTitleWithCTA from "../../atoms/CollectionTitleWithCTA";
import StoryCardDLTextOverley from "../../atoms/story-card-dl-text-overley";
import Icon from "../../atoms/icon";
import { getStoryHeadline } from "../../utils/utils";

import styles from "./styles.m.css";

const SliderFocusedWithTextOverley = ({
  collection,
  numberOfCardsInViewDesktop = 2.5,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 0.7,
  accentColor
}) => {
  const desktopGap = 20;
  const mobileGap = 0;

  const [pageName, setPageName] = useState("home");

  useEffect(() => {
    const getpageName = global && global.location.pathname;
    const pageName = getpageName.split("/").pop();
    const getPFrom = pageName || "home";
    setPageName(getPFrom);
  }, []);

  const slides = collection.items
    .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
    .map(({ story }) => (
      <StoryCardDLTextOverley
        key={story.id}
        story={story}
        className={styles.card}
        pFrom={`${pageName}-story-card-slider`}
      />
    ));

  return (
    <div className={styles.base}>
      <div className={`container ${styles["carousel-wrapper"]} ${styles["container-story"]}`}>
        {collection.name && (
          <CollectionLink collection={collection}>
            <CollectionTitleWithCTA className={styles.title} title={collection.name} theme="red" />
          </CollectionLink>
        )}
        <Carousel
          className={styles["carousel"]}
          options={{
            type: "carousel",
            perView: numberOfCardsInViewDesktop,
            gap: desktopGap,
            rewind: false,
            peek: {
              before: 0,
              after: 244
            },
            breakpoints: {
              0: {
                perView: numberOfCardsInViewMobile,
                gap: mobileGap,
                peek: {
                  before: mobileGap,
                  after: 144
                }
              },
              767: {
                perView: numberOfCardsInViewMobile,
                gap: mobileGap,
                peek: {
                  before: mobileGap,
                  after: 144
                }
              },
              991: {
                perView: numberOfCardsInViewTablet,
                gap: desktopGap,
                peek: {
                  before: desktopGap,
                  after: 244
                }
              }
            }
          }}
          renderCenterLeftControls={({ previousSlide, currentSlide }) =>
            currentSlide > 0 ? (
              <button
                aria-label="previous slide"
                role="button"
                onClick={previousSlide}
                className={`${styles["slider-nav-button"]}`}
              >
                <Icon type="angle-left" className={styles.icon} />
              </button>
            ) : null
          }
          renderCenterRightControls={({ nextSlide }) => (
            <button
              aria-label="next slide"
              role="button"
              onClick={nextSlide}
              className={`${styles["slider-nav-button"]}`}
            >
              <Icon type="angle-right" className={styles.icon} />
            </button>
          )}
          childClass={styles["glide_li"]}
        >
          {slides}
        </Carousel>
      </div>
    </div>
  );
};

SliderFocusedWithTextOverley.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default SliderFocusedWithTextOverley;
