import React from "react";
import PropTypes from "prop-types";
import produce from "immer";
import CollectionLink from "../../utils/generate-collection-link";

import BigStoryCardCurve from "../../atoms/big-story-card-curve/index";
import TenStoryTwoCollectionStoryStack from "../../atoms/ten-story-2c-story-stack/index";

import styles from "./styles.m.css";

import { wrapCollectionLayout } from "@quintype/components";
import { getStoryHeadline } from "../../utils/utils";
import Media from "react-media";
import { PGAdwithAd } from "../ad-free";
import Icon from "../../atoms/icon";
import { get } from "lodash";

const WrappedStoryStack = wrapCollectionLayout(TenStoryTwoCollectionStoryStack);

const mobileStoryCount = 5;

const desktopEvenStoryCount = 5;

const TenStory2CAuthor = ({ collection, metadata, accentColor, pageType }) => {
  const { items } = collection;
  const customSlug = get(collection, ["associated-metadata", "custom_slug"], false);
  const customSlugName = get(collection, ["associated-metadata", "slug_name"], "news");
  let stacks = items
    .filter(item => item.type === "collection")
    .slice(0, 2)
    .map((collection, index) => {
      collection = produce(collection, draft => {
        draft.items = draft.items
          .filter(({ story = {} }) => getStoryHeadline(story) != null && story.url != null)
          .slice(0, index % 2 === 0 ? desktopEvenStoryCount : desktopEvenStoryCount);
      });
      const bgColorClass = index === 0 ? "black-bg" : "red-bg";
      return (
        <React.Fragment key={collection.id}>
          <CollectionLink
            collection={collection}
            className={styles["collection-title"]}
            customSlug={customSlug}
            slugName={customSlugName}
          >
            <h2 className={styles["sub-collection-headline"]}>
              {collection.name}
              <div className={styles["collection-cta"]}>
                <Icon type={"slider-next"} />
              </div>
            </h2>
          </CollectionLink>
          <WrappedStoryStack
            bigStory={index % 2 === 0}
            bgColorClass={bgColorClass}
            mobileStoryCount={mobileStoryCount}
            collection={collection}
          />
          {index === 0 && (
            <div className="hidden-desktop">
              <Media query="(max-width:768px)">
                <PGAdwithAd
                  adId={`Vikatan2023_Mobile_HP_MID1_336x280`}
                  adSize={[[336, 280], [300, 250]]}
                  minHeight={280}
                  adDivId="div-ad-desk-3849069-169028051723-4"
                  class=""
                />
              </Media>
            </div>
          )}
        </React.Fragment>
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={styles["twelve-story-base"]}>
      <div className={`${styles["twelve-story-container-story"]} container`}>
        <div className={styles["twelve-story-stack-grid"]}>{stacks}</div>
      </div>
    </div>
  );
};

const Collection = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  readMoreText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(BigStoryCardCurve.propTypes))
});

TenStory2CAuthor.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(Collection)
  }),
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  }),
  pageType: PropTypes.string,
  bgColorClass: PropTypes.string
};

export default TenStory2CAuthor;
